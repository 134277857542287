import { Component, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DateAdapter } from '@angular/material/core';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { serviceCallArgument, WebService } from '../_communication/web-service';
import { BehaviorSubject } from 'rxjs';
import { News, NewsDetail } from '../_model';
import * as $ from 'jquery';

@Component({
    selector: 'app-news',
    templateUrl: './news.component.html',
    styleUrls: ['./news.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class NewsComponent implements OnInit {
    @ViewChild('newsModalContent') modalContent: TemplateRef<any> | undefined;
    news_detail: string = "Haber Detayı";
    close: string = "Kapat";
    SelectedStartDate = moment(new Date()).add('days', -30).format("YYYY-MM-DD");
    SelectedEndDate = moment(new Date()).format("YYYY-MM-DD");

    news: BehaviorSubject<News>;
    newsDetail: BehaviorSubject<NewsDetail>;
    newsData: any = [];
    newsDatas: any = [];
    newsDetailData: any = [];

    exceptionMessage: string = "";

    constructor(private httpClient: HttpClient, private router: Router, private modalService: NgbModal, private dateAdapter: DateAdapter<Date>) {
        moment.locale("tr");
        this.dateAdapter.setLocale('tr');
    }

    ngOnInit() {
        this.GetNews(true);
    }

    onSelectedStartDate() {
        this.GetNews(false);

    }
    onSelectedEndDate() {
        this.GetNews(false);
    }

    GetNews(first: boolean) {
        var service = new WebService(this.httpClient, this.router);
        var args = new serviceCallArgument();
        args.Controller = "Snapshot";
        args.Action = "News";
        args.JPostContent = { date: this.SelectedStartDate, enddate: this.SelectedEndDate, isFirst: first };

        args.FinallyHandler = (status, object) => {
            if (status == "success") {
                this.news = new BehaviorSubject<News>(JSON.parse(object.Data));

                this.newsDatas = this.news.value;
                for (var i = 0; i < this.newsDatas.length; i++) {
                    this.newsDatas[i]["date"] = moment(this.newsDatas[i]["date"]).add(3, 'hours');
                }
                this.newsData = this.newsDatas;

            }
            else {
                this.exceptionMessage = object.Data;
            }
        };
        service.Post(args);
    }

    GetNewsDetail(date: object, header: string, newsId: string) {
        this.modalService.open(this.modalContent, { windowClass: 'news-detail-content' })
        document.getElementById('newsModalBody').innerHTML = '';
        document.getElementById('newsModalHeader').innerHTML = header;

        document.getElementById('newsModalHeaderDate').innerHTML = moment.utc(date).local().format('DD.MM.YYYY HH:mm:ss');


        var service = new WebService(this.httpClient, this.router);
        var args = new serviceCallArgument();
        args.Controller = "Snapshot";
        args.Action = "NewsDetail";
        args.JPostContent = { id: newsId };

        args.FinallyHandler = (status, object) => {
            if (status == "success") {
                
                this.newsDetail = new BehaviorSubject<NewsDetail>(JSON.parse(object.Data));
                this.newsDetailData = this.newsDetail.value;

                var content = this.newsDetailData[0]["body"];
                var parser = new DOMParser();
                var htmlDoc = parser.parseFromString(content, 'text/html');

                $('.fa-question-circle', htmlDoc).css('display', 'none');
                $('iframe', htmlDoc).each(function (index, element) {
                    var iframe = $(this)[index];

                    var iframeSrc = $(iframe).attr('src');

                    if (iframeSrc.indexOf('embed') == -1) {
                        if (iframeSrc.indexOf('youtu.be') != -1) {
                            let youtubeSrcId = iframeSrc.split('/')[iframeSrc.split('/').length - 1];

                            $(iframe).attr('src', 'https://www.youtube.com/embed/' + youtubeSrcId);
                        } else if (iframeSrc.indexOf('youtube.com') != -1) {
                            var youtubeParams = iframeSrc.split('?')[1];

                            var urlParams = new URLSearchParams(youtubeParams);
                            let youtubeSrcId = urlParams.get('v');

                            $(iframe).attr('src', 'https://www.youtube.com/embed/' + youtubeSrcId);
                        }
                    }
                });
               
                var stringHtmlContent = $('body', htmlDoc).html();

                if (stringHtmlContent.substring(0, 5) == '<?xml') {
                    $('#newsModalBody').append(stringHtmlContent.split('</head>')[1]);
                    $('#newsModalBody .fa-question-circle').remove();
                    $('#newsModalBody .gwt-Label').css('text-align', 'left');
                } else {
                    $('#newsModalBody').append(stringHtmlContent);
                }
            }
            else {
                this.exceptionMessage = object.Data;
            }
        };
        service.Post(args);


    }
}