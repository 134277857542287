import { Component, Inject, OnInit, ViewChild, ViewEncapsulation, TemplateRef, AfterViewInit } from '@angular/core';
import * as $ from 'jquery';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { DateAdapter } from '@angular/material/core';
import { CompanyCard, IndexWeight, News, NewsDetail } from '../_model';
import { serviceCallArgument, WebService } from '../_communication/web-service';
import * as Chart from 'chart.js';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import "moment/locale/tr";

@Component({
    selector: 'app-equity-snapshot',
    templateUrl: './equity-snapshot.component.html',
    providers: [NgbModal],
    styleUrls: ['./equity-snapshot.component.scss'],
    encapsulation: ViewEncapsulation.None,
})

export class EquitySnapshotComponent implements OnInit, AfterViewInit {
    tableStatu: boolean = false;
    pageTitle: string = "Hisse Piyasa Verileri";
    tableTitle: string = "Şirket Bilgileri"
    chartTitle: string = "Fiyat Performansı";
    IndexTitle: string = "Endeks Kapsam Bilgisi"
    tableDate: string = "Tarih";
    tableData: string = "Veri";
    tableIndex: string = "Endeks";
    tableWeight: string = "Ağırlık"
    weekly: string = "h"
    month: string = "a";
    langId: number = 1;
    selectedfrequency: string = "1m";
    copyRightStatu: boolean = true;
    indexWeight: BehaviorSubject<IndexWeight>;
    indexWeightData: any = [];
    note: string = "* Tüm günlük veriler bir önceki günün kapanış verilerinden hesaplanmaktadır.";
    gridData: object[] = [];
    priceChartData: Chart;
    priceChartCanvas: any;
    priceChartCtx: any;

    companyCard: BehaviorSubject<CompanyCard>;
    companyCardData: any = [];
    companyCardDate: string;

    exceptionMessage: string = "";

    constructor(private http: HttpClient, private router: Router) {

        moment.locale("tr");
        this.companyCardDate = moment(new Date()).format("DD.MM.YYYY")

        let path = this.router.url;

        if (path != "/gorunum")
            this.langId = 2;

        if (this.langId == 2) {
            this.pageTitle = "Stock Market Data"
            this.tableTitle = "Company Information"
            this.chartTitle = "Price Performance"
            this.IndexTitle = "Index Coverage"
            this.tableDate = "Date";
            this.tableData = "Data";
            this.tableIndex = "Index";
            this.tableWeight = "Weight";
            this.weekly = "w"
            this.month = "m"
            this.copyRightStatu = false;
            this.note = "* Daily data are calculated from the previous day's closing.";
            moment.locale("en");
            this.companyCardDate = moment(new Date()).format("MM/DD/YYYY")
        }


    }
    ngOnInit() {

        this.GetIndexWeight();
        this.GetPriceChart();
        this.GetCompanyCard();
        let _this = this;
        $('.chartOptions .item').click(function () {
            $('.chartOptions .item').removeClass("active");
            $(this).addClass("active");
            _this.selectedfrequency = $(this).data("date");

            _this.GetPriceChart();
        });
    }

    ngAfterViewInit() {
        this.priceChartCanvas = document.getElementById('priceChart');
        this.priceChartCanvas.height = 300;
        this.priceChartCtx = this.priceChartCanvas.getContext('2d');
        this.priceChartData = new Chart(this.priceChartCtx, {
            type: 'line',
            options: {
                legend: {
                    display: false
                },
                maintainAspectRatio: false,
                responsive: true,
                tooltips: {
                    mode: 'index',
                    intersect: false,
                },
                scales: {
                    yAxes: [{


                        gridLines: {
                            color: "#f0f0f0",
                        },
                        ticks: {
                            beginAtZero: false,
                            fontColor: "#52595f",
                            fontSize: 12,
                        }
                    }]

                },
                elements: {
                    point: {
                        radius: 0
                    }
                }

            }
        });
    }

    tableAction() {
        if (this.tableStatu) {
            this.tableStatu = false;
        }
        else {
            this.tableStatu = true;
        }
    }

    GetIndexWeight() {
        var service = new WebService(this.http, this.router);
        var args = new serviceCallArgument();
        args.Controller = "Snapshot";
        args.Action = "IndexWeight";
        args.JPostContent = {};

        args.FinallyHandler = (status, object) => {
            if (status == "success") {
                this.indexWeight = new BehaviorSubject<IndexWeight>(JSON.parse(object.Data));
                this.indexWeightData = this.indexWeight.value;
            }
            else {
                this.exceptionMessage = object.Data;
            }
        };
        service.Post(args);
    }

    GetPriceChart() {
        var service = new WebService(this.http, this.router);
        var args = new serviceCallArgument();
        args.Controller = "Snapshot";
        args.Action = "PriceChart";
        args.JPostContent = { frequency: this.selectedfrequency };

        args.FinallyHandler = (status, object) => {
            if (status == "success") {
                let data: any = [];
                let label: any = [];
                this.gridData = [];
                let values = JSON.parse(object.Data);

                for (var i = 0; i < values.length; i++) {
                    data.push(values[i]["y"].toFixed(2));
                    label.push(moment(new Date(values[i]["x"].toString().split('T')[0])).format("yyyy-MM-DD"));

                }

                for (var k = values.length - 1; k > -1; k--) {
                    this.gridData.push({ x: values[k]["x"], y: values[k]["y"] });
                }

                this.priceChartData.data.labels = label;
                this.priceChartData.data.datasets = [{ data: data, borderColor: ['#76a243'], fill: false, borderWidth: 1 }];
                if (this.selectedfrequency == "1w" || this.selectedfrequency == "1m")
                    this.priceChartData.options.scales.xAxes = [{ type: 'time', distribution: 'linear', gridLines: { display: false }, ticks: { fontColor: "#52595f", fontSize: 12, padding: 15 }, time: { unit: 'day' } }];
                else
                    this.priceChartData.options.scales.xAxes = [{ type: 'time', distribution: 'linear', gridLines: { display: false }, ticks: { fontColor: "#52595f", fontSize: 12, padding: 15 }, time: { unit: 'month' } }];

                this.priceChartData.update();
            }
            else {
                this.exceptionMessage = object.Data;
            }
        };
        service.Post(args);
    }

    GetCompanyCard() {
        var service = new WebService(this.http, this.router);
        var args = new serviceCallArgument();
        args.Controller = "Snapshot";
        args.Action = "CompanyCard";
        args.JPostContent = {};

        args.FinallyHandler = (status, object) => {
            if (status == "success") {
                let datas = JSON.parse(object.Data);

                for (var i = 0; i < datas.length; i++) {
                    if (datas[i]["value"] == "NaN") {
                        datas[i]["value"] = "-";
                    }
                }
                this.companyCard = new BehaviorSubject<CompanyCard>(datas);
                this.companyCardData = this.companyCard.value;
            }
            else {
                this.exceptionMessage = object.Data;
            }
        };
        service.Post(args);
    }

}